import logo from './img/logo.svg';
import bg from './img/bg.png';
import sportsTherapy from './img/sports-therapy-small.png';
import prenatalMassage from './img/prenatal-massage-small.png';

import React, { useEffect, useState } from 'react'
import Overlay from './Overlay';

function App() {

    const [massageOverlayOpen, setMassageOverlayOpen] = useState(false)
    const [sportsOverlayOpen, setSportsOverlayOpen] = useState(false)
    const [isAnimating, setIsAnimating] = useState(false)

    const closeOverlay = () => {
        setSportsOverlayOpen(false)
        setMassageOverlayOpen(false)
       
        animationHover()
    }
    
    useEffect(() => {
        animatePaths()
    }, [])

    useEffect(() => {
        if (isAnimating) {
            setTimeout(() => {
                const paths = document.querySelectorAll('.svg-wrapper path');
                paths.forEach((path, index) => {    
                    path.removeAttribute('style');
                });
                setIsAnimating(false)
            }, 4200);
        }
    }, [isAnimating])

    const animatePaths = () => {

        setIsAnimating(true)
        const paths = document.querySelectorAll('.svg-wrapper path');
        var styleEl = document.createElement("style");
        
        paths.forEach((path, index) => {
            let animationName = `animation${index}`;
            let keyframes = `
                .svg-wrapper path:nth-child(${index}) {
                    -webkit-animation: ${animationName} 3s ease-in;
                    animation: ${animationName} 3s ease-in;
                    -webkit-animation-delay: ${parseFloat(index / 10 + 0.6).toFixed(2)}s;
                    animation-delay: ${parseFloat(index / 10 + 0.6).toFixed(2)}s;
                }
                @keyframes ${animationName} {
                    50% {
                        -webkit-transform: scale(1); 
                        transform: scale(1); 
                        fill-opacity: 0.7; 
                    }
                }
            `;
            styleEl.innerHTML += keyframes;
        })
        document.head.appendChild(styleEl);
    }

    const animationHover = () => {  
        if (!isAnimating) {
            setIsAnimating(true)
            const paths = document.querySelectorAll('.svg-wrapper path');
            paths.forEach((path, index) => {    
                let animationName = `animation${index}`;
                path.style.animationName = animationName;
            });
        }
    }
    
    return (
        <div className={'pt-5 pb-10 px-3 h-auto w-full flex flex-col justify-between bg-no-repeat bg-center bg-cover md:p-5 md:pb-10 ' + `${(sportsOverlayOpen || massageOverlayOpen) ? 'min-h-[1050px] lg:min-h-screen' : 'min-h-screen md:min-h-screen'}`}
            style={{ 
                backgroundImage: `url(${bg})`,
            }}> 
            <header className='w-full max-w-7xl mx-auto mb-4'>
                <div className='flex w-full justify-between'>
                    <p className='flex md:block tk-tt-commons-pro text-secondary uppercase text-xs flex-1 text-center tracking-normal md:text-left md:w-1/2'>
                        Monday & Tuesday: 10am - 2pm<span className='flex-1 px-0.5 md:px-3'>|</span>Wednesday: 2pm - 9pm
                    </p>
                    <p className='tk-tt-commons-pro text-secondary uppercase text-xs flex-1 text-right tracking-normal hidden md:block md:w-1/2'>
                        Fit Performance Gym, Unit 4 Chester Hall Lane, Basildon, SS14 3BG
                    </p>
                </div>
                <div className='flex gap-4 py-5 w-full flex-col items-center relative md:flex-row md:justify-center'>
                    <img src={logo} className='w-12 md:w-14 h-auto' alt="logo" />
                    <div className='flex gap-4 md:absolute md:right-0'>
                        <a className='tk-nimbus-sans-extended uppercase underline text-sm text-primary hover:no-underline' target='_blank' href="https://www.google.com/maps/place/Fit+Performance/@51.5815767,0.4552276,15z/data=!4m6!3m5!1s0x47d8c6ad881a353f:0x5990a504930c9bd8!8m2!3d51.5815767!4d0.4552276!16s%2Fg%2F1hc1jfqp2?entry=ttu">Directions</a>
                        <a className='tk-nimbus-sans-extended uppercase underline text-sm text-primary hover:no-underline' href='mailto:info@hannahpizzulo.co.uk'>Contact</a>
                    </div>
                </div>
            </header>
            {/*  onMouseEnter={() => animationHover()}  */}
            <div className='overflow-visible flex-1 min-h-[400px] relative mb-12 mt-2 max-w-full text-center flex w-full flex-col items-center justify-center mx-auto md:py-12 md:min-h-[720px] lg:min-h-[750px]'>
                <div className='flex items-center overflow-visible z-0 w-full h-full absolute max-w-[800px] mx-auto top-1/2 -translate-y-1/2 lg:max-h-[860px] md:max-w-[900px]'>
                    <svg className='overflow-visible svg-wrapper' width="100%" height="100%" viewBox="0 0 828 721" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_23_155)">
                            <path d="M36.8947 192.926C16.1058 205.554 3.34723 227.611 2.76184 251.935V590.956L350.965 382.144L350.68 4.87984L36.8947 192.926ZM483.774 456.212C496.383 456.212 508.316 458.735 518.808 464.816C545.571 480.326 558.434 517.098 557.038 574.094C553.151 615.325 563.928 652.397 589.1 684.379C615.623 718.087 669.884 728.522 707.604 707.201L795.849 653.823C813.695 643.028 824.758 623.373 824.743 602.533C824.713 578.524 824.953 519.71 825.103 480.792C825.178 461.978 825.238 447.744 825.238 444.231C825.238 378.66 805.665 333.571 767.044 310.223C699.679 269.487 597.115 306.679 563.283 324.442C510.342 352.234 23.836 647.067 2.76184 659.829V686.526C2.76184 692.982 6.10909 698.733 11.7078 701.916C17.2916 705.084 23.926 705.009 29.4497 701.706C98.1058 660.64 323.947 525.506 347.888 510.837C350.275 509.485 353.472 507.548 357.36 505.191C383.852 489.14 438.174 456.197 483.774 456.197V456.212ZM663.58 721C634.295 721 604.62 708.568 586.923 686.075C561.316 653.523 550.329 615.791 554.276 573.929C555.642 518.104 543.244 482.173 517.412 467.203C470.355 439.921 389.301 489.065 358.785 507.563C354.868 509.936 351.641 511.888 349.269 513.224C325.373 527.863 99.5017 663.013 30.8457 704.078C24.4664 707.907 16.7962 707.982 10.3269 704.318C3.84257 700.64 -0.0300293 693.988 -0.0300293 686.526V658.268L0.630413 657.862C5.70381 654.784 508.046 350.282 561.962 321.979C596.17 304.007 699.964 266.439 768.425 307.835C807.932 331.724 827.955 377.594 827.955 444.216C827.955 447.729 827.895 461.963 827.82 480.777C827.67 519.695 827.43 578.524 827.46 602.503C827.49 624.304 815.902 644.859 797.244 656.151L708.97 709.559C695.281 717.306 679.475 720.985 663.55 720.985L663.58 721ZM-9.17539e-06 595.821V251.89C0.615403 226.59 13.8843 203.677 35.4688 190.554L353.442 0L353.727 383.706L353.052 384.111L-9.17539e-06 595.821Z" fill="white" />
                            <path d="M84.6268 215.208C65.3839 226.905 53.5709 247.325 53.0156 269.833V583.719L375.402 390.402L375.146 41.1108L84.6268 215.208ZM498.364 458.975C510.027 458.975 521.074 461.302 530.801 466.933C555.582 481.287 567.485 515.341 566.195 568.118C562.592 606.286 572.574 640.61 595.869 670.22C620.426 701.421 670.665 711.09 705.593 691.346L787.293 641.917C803.819 631.917 814.071 613.734 814.041 594.439C814.011 572.217 814.236 517.758 814.371 481.723C814.446 464.305 814.491 451.122 814.491 447.879C814.491 387.159 796.359 345.418 760.605 323.811C698.223 286.094 603.284 320.523 571.958 336.979C522.951 362.715 72.5136 635.685 53.0006 647.517V672.232C53.0006 678.208 56.0926 683.538 61.2861 686.481C66.4646 689.424 72.6037 689.349 77.7071 686.286C141.275 648.268 350.125 522.743 372.535 509.575C374.726 508.284 377.713 506.527 381.316 504.35C405.842 489.485 456.141 458.99 498.349 458.99L498.364 458.975ZM664.841 704.138C637.733 704.138 610.249 692.622 593.873 671.796C570.157 641.646 559.995 606.722 563.643 567.953C564.904 516.272 553.421 482.999 529.51 469.14C485.951 443.885 410.9 489.38 382.652 506.512C379.019 508.719 376.017 510.476 373.841 511.753C351.461 524.906 142.596 650.43 79.028 688.463C73.114 692.006 66.0143 692.081 60.0253 688.688C54.0212 685.28 50.4489 679.124 50.4489 672.217V646.061L51.0643 645.685C55.7624 642.848 520.864 360.913 570.773 334.697C602.444 318.06 698.538 283.286 761.926 321.604C798.49 343.721 817.043 386.198 817.043 447.864C817.043 451.122 816.998 464.29 816.923 481.723C816.773 517.758 816.562 572.217 816.592 594.425C816.622 614.605 805.89 633.643 788.614 644.094L706.884 693.538C694.2 700.7 679.566 704.108 664.826 704.108L664.841 704.138ZM50.4639 588.238V269.803C51.0342 246.379 63.3125 225.163 83.3059 213.016L377.683 36.5913L377.953 391.844L377.338 392.219L50.4639 588.238Z" fill="#FBF8F7"/>
                            <path d="M132.344 237.491C114.647 248.241 103.78 267.025 103.269 287.745V576.497L399.838 398.66L399.598 77.3417L132.344 237.506V237.491ZM512.954 461.738C523.686 461.738 533.848 463.885 542.794 469.065C565.594 482.278 576.551 513.584 575.351 562.142C572.033 597.247 581.219 628.824 602.654 656.061C625.244 684.769 671.46 693.658 703.581 675.505L778.737 630.04C793.942 620.851 803.368 604.109 803.338 586.361C803.308 565.926 803.518 515.822 803.654 482.669C803.714 466.648 803.759 454.531 803.759 451.543C803.759 395.687 787.082 357.294 754.195 337.4C696.812 302.7 609.468 334.382 580.649 349.502C535.559 373.18 121.206 624.289 103.254 635.16V657.907C103.254 663.403 106.106 668.298 110.879 671.015C115.637 673.718 121.296 673.658 125.994 670.835C184.459 635.851 376.602 520.386 397.211 508.269C399.223 507.083 401.969 505.476 405.287 503.464C427.847 489.786 474.123 461.738 512.954 461.738ZM666.086 687.262C641.14 687.262 615.878 676.661 600.808 657.517C578.998 629.785 569.632 597.653 573.009 561.992C574.165 514.455 563.613 483.84 541.608 471.092C501.531 447.864 432.5 489.711 406.502 505.476C403.155 507.503 400.393 509.125 398.397 510.296C377.818 522.398 185.674 637.878 127.195 672.847C121.762 676.105 115.232 676.181 109.723 673.057C104.2 669.919 100.913 664.259 100.913 657.907V633.839L101.483 633.493C105.806 630.881 533.653 371.528 579.568 347.415C608.703 332.114 697.097 300.118 755.426 335.373C789.064 355.718 806.13 394.786 806.13 451.528C806.13 454.516 806.085 466.633 806.025 482.669C805.89 515.822 805.695 565.911 805.71 586.346C805.74 604.92 795.863 622.427 779.968 632.037L704.782 677.517C693.12 684.108 679.655 687.246 666.101 687.246L666.086 687.262ZM100.913 580.641V287.715C101.438 266.169 112.741 246.65 131.128 235.479L401.939 73.1826L402.18 399.982L401.609 400.327L100.913 580.641Z" fill="#F8F4F1"/>
                            <path d="M180.061 259.773C163.895 269.592 153.973 286.739 153.523 305.658V569.26L424.274 406.904L424.049 113.558L180.061 259.773ZM527.529 464.486C537.33 464.486 546.606 466.438 554.772 471.167C575.576 483.224 585.588 511.813 584.507 556.137C581.475 588.193 589.865 617.022 609.438 641.887C630.062 668.088 672.256 676.211 701.585 659.634L770.196 618.133C784.066 609.74 792.681 594.454 792.651 578.253C792.621 559.59 792.816 513.855 792.937 483.585C792.997 468.945 793.042 457.894 793.042 455.161C793.042 404.171 777.821 369.111 747.786 350.958C695.401 319.277 615.653 348.195 589.355 362.009C548.197 383.63 169.914 612.878 153.523 622.803V643.568C153.523 648.583 156.12 653.058 160.488 655.535C164.84 657.998 169.989 657.938 174.282 655.37C227.658 623.433 403.065 518.029 421.888 506.963C423.734 505.882 426.226 504.41 429.258 502.578C449.852 490.086 492.09 464.486 527.544 464.486H527.529ZM667.332 670.37C644.562 670.37 621.492 660.7 607.727 643.208C587.809 617.893 579.268 588.554 582.345 556.001C583.411 512.593 573.76 484.651 553.691 473.014C517.112 451.798 454.069 490.011 430.353 504.395C427.306 506.242 424.785 507.728 422.953 508.795C404.161 519.846 228.753 625.265 175.363 657.187C170.394 660.16 164.435 660.22 159.407 657.367C154.363 654.514 151.361 649.334 151.361 643.538V621.571L151.887 621.256C155.819 618.869 546.426 382.099 588.349 360.087C614.947 346.108 695.656 316.904 748.897 349.096C779.608 367.67 795.188 403.345 795.188 455.131C795.188 457.864 795.143 468.93 795.083 483.57C794.963 513.84 794.783 559.575 794.798 578.223C794.813 595.175 785.822 611.166 771.292 619.935L702.651 661.466C692.009 667.487 679.716 670.355 667.332 670.355V670.37ZM151.361 573.043V305.613C151.842 285.944 162.154 268.121 178.95 257.926L426.181 109.759L426.406 408.105L425.88 408.42L151.361 573.043Z" fill="#F5F0EC"/>
                            <path d="M227.778 282.055C213.158 290.944 204.182 306.454 203.762 323.556V562.022L448.696 415.147L448.501 149.774L227.778 282.055ZM542.118 467.248C550.989 467.248 559.38 469.02 566.765 473.299C585.588 484.215 594.639 510.071 593.663 550.176C590.931 579.169 598.511 605.25 616.208 627.742C634.866 651.451 673.036 658.793 699.574 643.793L761.641 606.241C774.189 598.644 781.979 584.83 781.964 570.175C781.949 553.299 782.114 511.918 782.219 484.531C782.279 471.287 782.309 461.287 782.309 458.825C782.309 412.699 768.53 380.988 741.377 364.562C693.99 335.913 621.852 362.069 598.061 374.561C560.821 394.111 218.622 601.496 203.792 610.49V629.274C203.792 633.809 206.148 637.863 210.081 640.1C214.013 642.337 218.682 642.277 222.554 639.95C270.842 611.061 429.528 515.701 446.549 505.686C448.215 504.71 450.482 503.374 453.214 501.723C471.841 490.431 510.057 467.263 542.133 467.263L542.118 467.248ZM668.593 653.508C647.999 653.508 627.12 644.754 614.677 628.944C596.665 606.046 588.935 579.5 591.712 550.055C592.672 510.792 583.951 485.506 565.789 474.981C532.692 455.792 475.669 490.356 454.204 503.374C451.443 505.041 449.161 506.392 447.51 507.353C430.504 517.338 271.817 612.713 223.53 641.601C219.042 644.289 213.638 644.349 209.09 641.766C204.527 639.184 201.81 634.499 201.81 629.259V609.379L202.276 609.094C205.833 606.932 559.2 392.744 597.115 372.835C621.176 360.192 694.185 333.766 742.353 362.895C770.136 379.697 784.231 411.963 784.231 458.825C784.231 461.302 784.186 471.302 784.141 484.545C784.036 511.918 783.87 553.299 783.885 570.175C783.9 585.506 775.765 599.965 762.631 607.908L700.535 645.475C690.898 650.925 679.791 653.508 668.578 653.508H668.593ZM201.825 565.461V323.541C202.261 305.748 211.582 289.622 226.772 280.403L450.422 146.365L450.617 416.258L450.152 416.543L201.81 565.461H201.825Z" fill="#F2ECE7"/>
                            <path d="M275.51 304.337C262.436 312.28 254.406 326.168 254.03 341.469V554.8L473.132 423.405L472.952 186.004L275.51 304.337ZM556.708 470.011C564.634 470.011 572.154 471.588 578.758 475.416C595.599 485.176 603.69 508.314 602.819 544.185C600.372 570.13 607.157 593.448 622.993 613.583C639.684 634.799 673.832 641.361 697.563 627.953L753.085 594.364C764.312 587.578 771.277 575.205 771.262 562.097C771.247 546.992 771.397 509.981 771.487 485.491C771.532 473.645 771.577 464.696 771.577 462.489C771.577 421.228 759.254 392.85 734.953 378.165C692.564 352.535 628.021 375.928 606.737 387.114C573.429 404.606 267.284 590.13 254.03 598.163V614.965C254.03 619.019 256.132 622.637 259.659 624.649C263.171 626.646 267.344 626.601 270.827 624.514C314.026 598.674 455.976 513.359 471.211 504.41C472.697 503.539 474.723 502.338 477.17 500.852C493.831 490.747 528.024 470.026 556.708 470.026V470.011ZM669.854 636.631C651.422 636.631 632.749 628.809 621.627 614.649C605.506 594.169 598.601 570.416 601.078 544.079C601.934 508.96 594.128 486.332 577.887 476.918C548.273 459.756 497.268 490.672 478.071 502.323C475.609 503.825 473.568 505.026 472.082 505.882C456.876 514.816 314.911 600.13 271.712 625.971C267.705 628.373 262.871 628.433 258.804 626.121C254.721 623.809 252.289 619.619 252.289 614.92V597.142L252.709 596.887C255.892 594.95 572.003 403.345 605.926 385.522C627.451 374.216 692.759 350.583 735.853 376.618C760.71 391.648 773.318 420.522 773.318 462.429C773.318 464.636 773.288 473.585 773.228 485.431C773.123 509.921 772.973 546.932 773.003 562.037C773.018 575.761 765.738 588.689 753.985 595.791L698.433 629.394C689.817 634.259 679.866 636.586 669.854 636.586V636.631ZM252.289 557.863V341.439C252.679 325.523 261.025 311.093 274.609 302.85L474.678 182.941L474.858 424.396L474.438 424.651L252.274 557.878L252.289 557.863Z" fill="#F0E8E3"/>
                            <path d="M323.227 326.634C311.684 333.646 304.599 345.883 304.269 359.381V547.578L497.554 431.663L497.403 222.235L323.227 326.619V326.634ZM571.283 472.789C578.278 472.789 584.897 474.185 590.736 477.564C605.596 486.167 612.741 506.587 611.96 538.224C609.799 561.106 615.788 581.692 629.762 599.439C644.487 618.148 674.612 623.944 695.551 612.112L744.529 582.473C754.436 576.482 760.575 565.566 760.56 554.004C760.545 540.686 760.68 508.029 760.755 486.422C760.8 475.972 760.83 468.074 760.83 466.137C760.83 429.741 749.963 404.711 728.528 391.754C691.123 369.141 634.205 389.787 615.428 399.651C586.038 415.087 315.977 578.749 304.284 585.836V600.656C304.284 604.244 306.145 607.427 309.252 609.199C312.359 610.956 316.037 610.911 319.099 609.079C357.209 586.286 482.438 511.032 495.872 503.134C497.193 502.368 498.979 501.317 501.126 499.996C515.821 491.077 545.991 472.804 571.298 472.804L571.283 472.789ZM671.1 619.77C654.844 619.77 638.378 612.863 628.546 600.385C614.332 582.307 608.223 561.362 610.429 538.134C611.18 507.143 604.305 487.203 589.97 478.885C563.853 463.735 518.853 491.017 501.921 501.287C499.745 502.608 497.944 503.659 496.638 504.425C483.219 512.308 357.99 587.578 319.879 610.37C316.337 612.487 312.074 612.532 308.487 610.505C304.884 608.463 302.738 604.77 302.738 600.626V584.935L303.113 584.71C305.92 582.998 584.792 413.976 614.707 398.255C633.695 388.285 691.303 367.429 729.324 390.402C751.254 403.66 762.361 429.126 762.361 466.107C762.361 468.059 762.331 475.957 762.286 486.407C762.196 508.014 762.061 540.671 762.091 553.974C762.091 566.076 755.682 577.488 745.31 583.764L696.302 613.403C688.707 617.698 679.926 619.74 671.085 619.74L671.1 619.77ZM302.738 550.281V359.366C303.083 345.327 310.438 332.595 322.431 325.313L498.934 219.533L499.1 432.534L498.724 432.759L302.738 550.281Z" fill="#EEE5DE"/>
                            <path d="M370.944 348.916C360.947 354.982 354.808 365.598 354.523 377.294V540.341L521.99 439.921L521.855 258.466L370.944 348.916ZM585.873 475.552C591.937 475.552 597.671 476.768 602.729 479.681C615.608 487.143 621.792 504.831 621.116 532.233C619.24 552.052 624.434 569.89 636.532 585.265C649.29 601.481 675.393 606.496 693.525 596.241L735.958 570.566C744.544 565.371 749.873 555.926 749.858 545.896C749.858 534.35 749.963 506.062 750.038 487.338C750.068 478.284 750.098 471.452 750.098 469.756C750.098 438.21 740.686 416.528 722.104 405.297C689.697 385.702 640.374 403.585 624.103 412.129C598.646 425.492 364.654 567.293 354.523 573.449V586.286C354.523 589.394 356.129 592.157 358.831 593.689C361.517 595.22 364.715 595.175 367.356 593.584C400.378 573.824 508.871 508.629 520.519 501.783C521.66 501.107 523.206 500.206 525.082 499.065C537.826 491.347 563.943 475.507 585.873 475.507V475.552ZM672.346 602.908C658.266 602.908 643.992 596.932 635.481 586.106C623.158 570.446 617.874 552.293 619.78 532.173C620.441 505.326 614.467 488.044 602.054 480.837C579.418 467.714 540.437 491.347 525.757 500.251C523.866 501.392 522.305 502.308 521.179 502.969C509.562 509.801 401.054 575.01 368.032 594.755C364.97 596.587 361.277 596.632 358.155 594.875C355.033 593.103 353.172 589.905 353.172 586.316V572.728L353.487 572.533C355.919 571.061 597.536 424.606 623.458 410.988C639.909 402.339 689.832 384.276 722.764 404.186C741.767 415.672 751.389 437.744 751.389 469.771C751.389 471.468 751.359 478.299 751.329 487.353C751.254 506.077 751.133 534.365 751.148 545.896C751.148 556.377 745.595 566.272 736.619 571.707L694.17 597.397C687.581 601.121 679.986 602.893 672.331 602.893L672.346 602.908ZM353.187 542.698V377.279C353.487 365.117 359.866 354.096 370.253 347.775L523.176 256.124L523.311 440.672L522.981 440.867L353.172 542.698H353.187Z" fill="#EBE1DA"/>
                            <path d="M418.661 371.198C410.21 376.333 405.017 385.312 404.776 395.207V533.119L546.426 448.179L546.306 294.697L418.661 371.198ZM600.448 478.299C605.581 478.299 610.429 479.32 614.692 481.798C625.574 488.104 630.813 503.059 630.243 526.257C628.666 543.028 633.049 558.103 643.286 571.121C654.079 584.83 676.158 589.079 691.499 580.401L727.388 558.689C734.653 554.305 739.156 546.302 739.141 537.833C739.141 528.074 739.231 504.14 739.291 488.299C739.321 480.642 739.351 474.861 739.351 473.434C739.351 446.753 731.38 428.42 715.68 418.915C688.271 402.339 646.558 417.474 632.794 424.696C611.255 436.002 413.347 555.941 404.776 561.136V571.992C404.776 574.62 406.142 576.962 408.424 578.253C410.705 579.545 413.392 579.515 415.644 578.163C443.577 561.452 535.349 506.302 545.18 500.521C546.141 499.951 547.447 499.185 549.038 498.224C559.815 491.693 581.91 478.299 600.463 478.299H600.448ZM673.592 586.016C661.674 586.016 649.605 580.956 642.416 571.812C631.999 558.569 627.526 543.224 629.132 526.182C629.687 503.479 624.644 488.855 614.137 482.759C594.999 471.663 562.022 491.648 549.608 499.185C548.017 500.146 546.697 500.927 545.736 501.482C535.904 507.263 444.133 562.413 416.199 579.124C413.602 580.686 410.48 580.716 407.853 579.229C405.212 577.728 403.636 575.025 403.636 571.992V560.491L403.906 560.326C405.962 559.079 610.324 435.207 632.254 423.69C646.168 416.378 688.391 401.093 716.25 417.939C732.326 427.654 740.461 446.318 740.461 473.419C740.461 474.846 740.431 480.642 740.401 488.299C740.341 504.14 740.236 528.059 740.251 537.818C740.251 546.692 735.553 555.055 727.958 559.65L692.054 581.377C686.485 584.53 680.046 586.031 673.577 586.031L673.592 586.016ZM403.636 535.086V395.177C403.891 384.892 409.279 375.567 418.06 370.222L547.402 292.7L547.522 448.795L547.252 448.96L403.621 535.086H403.636Z" fill="#E9DED6"/>
                            <path d="M466.393 393.48C459.473 397.684 455.24 405.012 455.03 413.105V525.882L570.863 456.423L570.773 330.928L466.393 393.48ZM615.052 481.062C619.24 481.062 623.218 481.903 626.7 483.93C635.601 489.095 639.879 501.317 639.414 520.281C638.123 533.99 641.71 546.332 650.071 556.962C658.897 568.178 676.939 571.647 689.487 564.56L718.847 546.797C724.776 543.209 728.468 536.662 728.453 529.74C728.453 521.752 728.528 502.188 728.573 489.245C728.603 482.984 728.618 478.254 728.618 477.083C728.618 455.266 722.104 440.267 709.255 432.504C686.845 418.945 652.727 431.318 641.47 437.234C623.863 446.483 462.025 544.56 455.015 548.809V557.698C455.015 559.845 456.126 561.752 457.987 562.818C459.848 563.869 462.055 563.854 463.886 562.743C486.716 549.08 561.767 503.99 569.812 499.245C570.592 498.78 571.673 498.149 572.964 497.368C581.775 492.023 599.847 481.062 615.022 481.062H615.052ZM674.867 569.154C665.126 569.154 655.249 565.01 649.365 557.533C640.84 546.707 637.192 534.155 638.513 520.221C638.963 501.647 634.836 489.696 626.25 484.711C610.594 475.642 583.636 491.978 573.474 498.134C572.168 498.93 571.088 499.56 570.307 500.026C562.262 504.756 487.227 549.86 464.381 563.509C462.265 564.785 459.713 564.815 457.552 563.584C455.39 562.368 454.114 560.145 454.114 557.668V548.269L454.34 548.134C456.021 547.113 623.143 445.822 641.065 436.393C652.442 430.417 686.965 417.924 709.751 431.693C722.885 439.636 729.549 454.906 729.549 477.053C729.549 478.224 729.534 482.954 729.504 489.215C729.459 502.158 729.369 521.722 729.384 529.71C729.384 536.963 725.541 543.794 719.327 547.563L689.967 565.326C685.419 567.908 680.151 569.124 674.852 569.124L674.867 569.154ZM454.129 527.518V413.105C454.339 404.681 458.752 397.069 465.927 392.699L571.703 329.307L571.793 456.948L571.568 457.083L454.129 527.518Z" fill="#E7DAD1"/>
                            <path d="M514.11 415.762C508.736 419.021 505.434 424.726 505.284 431.017V518.659L595.299 464.681L595.224 367.159L514.11 415.777V415.762ZM629.627 483.825C632.884 483.825 635.976 484.47 638.678 486.047C645.598 490.056 648.915 499.56 648.555 514.29C647.549 524.951 650.341 534.53 656.84 542.803C663.7 551.512 677.719 554.215 687.476 548.704L710.291 534.905C714.899 532.113 717.766 527.038 717.766 521.647C717.766 515.446 717.826 500.236 717.856 490.176C717.871 485.311 717.886 481.633 717.886 480.732C717.886 463.78 712.828 452.128 702.846 446.092C685.434 435.567 658.912 445.176 650.176 449.771C636.487 456.963 510.732 533.179 505.284 536.482V543.389C505.284 545.056 506.154 546.542 507.595 547.368C509.036 548.194 510.762 548.164 512.188 547.308C529.93 536.692 588.259 501.647 594.504 497.969C595.119 497.608 595.945 497.113 596.95 496.512C603.795 492.368 617.844 483.84 629.627 483.84V483.825ZM676.113 552.278C668.548 552.278 660.878 549.064 656.3 543.254C649.68 534.83 646.844 525.086 647.864 514.26C648.21 499.831 645.012 490.536 638.333 486.678C626.175 479.621 605.221 492.323 597.325 497.113C596.305 497.729 595.479 498.224 594.864 498.584C588.62 502.263 530.29 537.308 512.549 547.923C510.897 548.914 508.916 548.929 507.25 547.983C505.569 547.037 504.578 545.311 504.578 543.389V536.092L504.743 535.987C506.049 535.191 635.916 456.483 649.846 449.155C658.687 444.516 685.525 434.801 703.221 445.507C713.428 451.678 718.607 463.54 718.607 480.762C718.607 481.663 718.607 485.341 718.577 490.221C718.532 500.281 718.472 515.491 718.487 521.692C718.487 527.323 715.5 532.638 710.681 535.566L687.866 549.365C684.324 551.362 680.241 552.323 676.128 552.323L676.113 552.278ZM504.578 519.921V431.017C504.743 424.471 508.166 418.555 513.749 415.162L595.944 365.898L596.02 465.086L595.839 465.191L504.578 519.921Z" fill="#E5D7CD"/>
                            <path d="M561.827 438.044C557.999 440.372 555.642 444.441 555.537 448.915V511.422L619.735 472.924L619.69 403.375L561.842 438.044H561.827ZM644.217 486.588C646.543 486.588 648.735 487.053 650.671 488.179C655.609 491.032 657.981 497.819 657.726 508.329C657.005 515.927 659.002 522.758 663.64 528.659C668.533 534.875 678.53 536.797 685.494 532.863L701.765 523.029C705.053 521.032 707.094 517.413 707.094 513.569C707.094 509.14 707.139 498.299 707.154 491.122C707.154 487.654 707.184 485.026 707.184 484.38C707.184 472.293 703.582 463.975 696.452 459.681C684.038 452.173 665.126 459.02 658.882 462.308C649.125 467.429 559.425 521.782 555.552 524.14V529.065C555.552 530.251 556.168 531.317 557.203 531.902C558.239 532.488 559.455 532.473 560.476 531.857C573.129 524.29 614.767 499.38 619.18 496.663C619.615 496.407 620.216 496.062 620.921 495.626C625.799 492.669 635.826 486.588 644.232 486.588H644.217ZM677.359 535.401C671.955 535.401 666.492 533.104 663.235 528.96C658.506 522.954 656.48 516.002 657.215 508.284C657.471 497.999 655.174 491.362 650.416 488.615C641.74 483.585 626.79 492.639 621.176 496.062C620.456 496.497 619.855 496.858 619.42 497.113C615.007 499.816 573.369 524.725 560.716 532.293C559.545 532.999 558.119 533.014 556.933 532.338C555.732 531.662 555.027 530.431 555.027 529.065V523.855L555.147 523.779C556.078 523.209 648.69 467.068 658.626 461.858C664.931 458.54 684.068 451.618 696.692 459.245C703.972 463.645 707.664 472.113 707.664 484.38C707.664 485.026 707.664 487.654 707.634 491.122C707.604 498.299 707.559 509.14 707.574 513.569C707.574 517.593 705.443 521.377 702.006 523.464L685.735 533.314C683.213 534.74 680.301 535.416 677.359 535.416V535.401ZM555.027 512.323V448.915C555.147 444.246 557.579 440.026 561.571 437.609L620.186 402.474L620.246 473.209L620.126 473.284L555.042 512.323H555.027Z" fill="#E3D3C9"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_23_155">
                            <rect width="828" height="721" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className='z-10 md:max-w-[720px]'>
                    <p className='tk-nimbus-sans-extended text-sm leading-tight uppercase font-bold text-bronze m-0 tracking-text px-4'>Sports Therapy and Prenatal Massage Care</p>
                    <h1 className='tk-nimbus-sans-extended mb-4 md:mb-5 leading-tight md:leading-snug text-primary font-light text-4xl tracking-title md:text-5xl'>
                        Supporting bodies in motion
                    </h1>
                    <div className='flex flex-col items-center justify-center gap-3 md:flex-row'>
                        <button className='tk-nimbus-sans-extended tracking-text text-sm rounded-button w-52 shadow-button h-12 bg-bronze text-white leading-tight uppercase font-normal hover:opacity-90' 
                            onClick={() => {setSportsOverlayOpen(true)}}>Sports therapy</button>
                        <button className='tk-nimbus-sans-extended tracking-text text-sm rounded-button w-52 shadow-button h-12 bg-bronze text-white leading-tight uppercase font-normal hover:opacity-90' 
                            onClick={() => {setMassageOverlayOpen(true)}}>Prenatal massage</button>
                    </div>
                </div>
            </div>

            { sportsOverlayOpen && 
                <Overlay bgImage={sportsTherapy} onClose={closeOverlay}>
                    <div className='flex-1 flex flex-col gap-3 md:gap-4 md:pt-16'>
                        <h2 className='font-light tk-nimbus-sans-extended text-greenTitle text-[34px] leading-none tracking-overlayTitle'>Sports Therapy</h2>
                        <div className='tk-tt-commons-pro text-base text-primary font-normal leading-tight tracking-normal md:pr-8'>
                            <p className='mb-3 md:mb-4'>Sports therapy offers a tailored approach to enhance your physical well-being. Whether you're an athlete recovering from an injury or looking to maximise your sports performance, I'm here to help.</p>
                            <p >Through specialised techniques and personalised care, sports therapy promotes faster healing, improved strength, and overall better athletic performance. With my expertise, you can reach your peak physical potential and stay in top shape.</p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-1.5 pb-6 md:pb-8 md:pt-20 md:gap-2'>
                        <h4 className='font-bold text-sm tk-nimbus-sans-extended leading-none tracking-text uppercase text-green'>1 hour session: £55</h4>
                        <p className='tk-tt-commons-pro text-xs text-primary'>Payments for sessions can be made via cash or BACS</p>
                        <a href="mailto:info@hannahpizzulo.co.uk?subject=Sports Therapy Booking" className='flex items-center justify-center mx-auto tk-nimbus-sans-extended tracking-text leading-none text-sm rounded-button w-52 shadow-button h-12 border-greenBorder border-2 text-green bg-greenButton uppercase font-normal hover:bg-greenButtonHover mt-6 md:mt-5 md:mx-0'>Book appointment</a>
                    </div>
                </Overlay>
            }
            
            { massageOverlayOpen && 
                <Overlay bgImage={prenatalMassage} onClose={closeOverlay}>
                    <div className='flex-1 flex flex-col gap-3 md:gap-4 md:pt-16'>
                        <h2 className='font-light tk-nimbus-sans-extended text-bronze text-[34px] leading-none tracking-overlayTitle'>Prenatal Massage</h2>
                        <div className='tk-tt-commons-pro text-base text-primary font-normal leading-tight tracking-normal md:pr-8'>
                            <p className='mb-4'>Pregnancy massages provide a soothing and nurturing experience for expectant mothers. Designed to alleviate discomfort and reduce stress during pregnancy, these massages offer numerous benefits. They can relieve muscle tension, reduce swelling, improve circulation, and promote relaxation, ultimately enhancing your overall well-being during this special time. </p>
                            <p>Trust in my expertise to provide you with a serene and comforting pregnancy massage experience, ensuring you feel your best as you journey through pregnancy.</p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-1.5 pb-6 md:pb-8 md:pt-20 md:gap-2'>
                        <h4 className='font-bold text-sm tk-nimbus-sans-extended leading-none tracking-text uppercase text-bronze'>1 hour session: £55</h4>
                        <p className='tk-tt-commons-pro text-xs text-primary'>Payments for sessions can be made via cash or BACS</p>
                        <a  href="mailto:info@hannahpizzulo.co.uk?subject=Prenatal Massage Booking" className='flex items-center justify-center mx-auto tk-nimbus-sans-extended tracking-text leading-none text-sm rounded-button w-52 shadow-button h-12 border-bronze border-2 text-bronze bg-bronzeButton uppercase font-normal hover:bg-bronzeButtonHover mt-6 md:mt-5 md:mx-0'>Book appointment</a>
                    </div>
                </Overlay>
            }

            <div className='max-w-full mx-auto md:max-w-[600px]'>
                <p className='tk-tt-commons-pro tracking-text flex text-center text-white leading-tight text-base'>I'm Hannah Pizzulo, I specialise in helping individuals recover from sports injuries, optimise sports team performance, and provide soothing pregnancy massages</p>
            </div>
        </div>
    );
}

export default App;
