const Overlay = ({
    bgImage,
    children,
    onClose,
    ...props
}) => {
    return (
        <div className='backdrop-blur-sm w-[95%] z-50 gap-6 h-auto top-[140px] -translate-x-1/2 p-2 md:p-3.5 absolute md:fixed left-1/2 bg-white/[0.85] rounded-overlay shadow-overlay flex flex-col 
            md:flex-row md:-translate-y-1/2 md:max-w-[95%] lg:max-w-[960px] md:top-1/2 md:w-full md:min-h-[620px] md:h-auto md:gap-8'>
            <div className='h-60 max-w-full w-full block bg-cover bg-no-repeat bg-center rounded-2xl md:rounded-xl md:w-96 md:h-auto md:flex-1' style={{ backgroundImage: `url(${bgImage})` }}></div>
            <div className='relative flex flex-col gap-6 md:gap-5 mb-3 md:mb-4 flex-1 px-3 md:px-0'>
                {children}
                <button className='text-bronze underline tracking-text font-primary uppercase text-sm md:absolute md:top-2.5 md:right-2.5 hover:no-underline' onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

export default Overlay;
